<template>
  <UiPopup
    v-model="modelValue"
    title="Weekly activities cleanup"
    :primary-button-text="step === 1 ? (overdueMeetingsCount ? 'Next' : 'Update activities') : 'Update activities'"
    :loading="loading || !selectedTasks.length || saving"
    secondary-button-text="Close"
    size="medium"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="input"
  >
    <template #title>
      <div class="flex w-full flex-row items-center justify-between">
        <h3>Weekly activities cleanup</h3>
        <span v-if="overdueMeetingsCount" class="text-caption-2 text-black-70">Step {{ step }}/2</span>
      </div>
    </template>
    <div class="mb-8 mt-2 flex flex-col gap-2 text-start">
      <span class="text-subhead-2">Tidy up your {{ step === 1 ? 'activities' : 'meetings' }}</span>
      <span>
        You have {{ step === 1 ? tasks.length : overdueMeetingsCount }} overdue "{{
          step === 1 ? 'Tasks' : 'Meetings'
        }}” activities from previous days. Mark as Done specific activity or select all at once.
      </span>
    </div>
    <div>
      <Transition name="fade" mode="out-in">
        <div v-if="step === 1">
          <UiStickyColumnsTable :items="tasks" :loading :columns class="text-start">
            <template #type="{ item }: { item: Activity }">
              <UiTagPrimary
                :id="`type_${item.id}`"
                class="!w-fit"
                :style="{
                  backgroundColor: CALENDAR_ACTIVITY_TYPES_COLORS_MAP.get(item.type?.code)?.background,
                }"
                right-icon=""
              >
                <div class="text-subhead-3 text-black-100">{{ item.type?.name }}</div>
              </UiTagPrimary>
            </template>
            <template #done="{ item }: { item: Activity }">
              <UiInputCheckbox
                :model-value="selectedTasks.includes(item)"
                :name="item.title"
                round
                border-color-class="border-black-30"
                class="mx-auto"
                @click.stop="toggleSelectedTask(item)"
              />
            </template>
          </UiStickyColumnsTable>
        </div>
        <div v-else class="min-h-[240px]">
          <img src="/assets/images/meeting_outcome_reminders.webp" alt="outcomes" class="mx-auto my-6 h-full w-72" />
        </div>
      </Transition>
    </div>
    <div class="absolute bottom-10 left-10">
      <UiButtonBase v-if="step === 1" id="mark-as-done" type="text" @click="markAllAsDone"
        >Mark all as Done
      </UiButtonBase>
      <UiButtonBase v-else id="other-outcomes" type="text" @click="navigateToOtherOutcomes"
        >Other pending outcomes <UiIcon name="arrow-small-right"></UiIcon
      ></UiButtonBase>
    </div>
  </UiPopup>
</template>

<script setup lang="ts">
import type { Activity, TableColumn } from '@/types'
import {
  CALENDAR_ACTIVITY_STATUSES,
  CALENDAR_ACTIVITY_TYPES_COLORS_MAP,
  CALENDAR_ACTIVITY_MEETING_OUTCOMES,
} from '~/constants'
import { useUiStore } from '~/store/ui'
import { useLibrariesStore } from '~/store/libraries'
const emits = defineEmits(['update:modelValue', 'input'])

const modelValue = defineModel<boolean>({ required: true })

const librariesStore = useLibrariesStore()

const { t: $t } = useI18n()

type Props = {
  tasks: Activity[]
}
const uiStore = useUiStore()

const columns: TableColumn[] = [
  { text: 'Activity Name', value: 'title' },
  { text: 'Type', slot: 'type', value: 'type' },
  { text: 'Done', slot: 'done', value: 'done', width: '100px', centerText: true },
]

const props = defineProps<Props>()

const step = ref(1)
const loading = ref(false)
const saving = ref(false)
const selectedTasks = ref<Activity[]>([])
const overdueMeetingsCount = ref()

onNuxtReady(async () => {
  loading.value = true
  await Promise.all([await useCalendarActivityStatuses(), await useMeetingOutcomeStatuses()])
  // this is only for step 2
  await getOverdueMeetings()
  loading.value = false
})

const getOverdueMeetings = async () => {
  try {
    const { pagination } = await useGetCalendarActivities({
      meeting_outcome_status_ids: librariesStore.getMeetingOutcomeStatuses
        .filter((status) => status.code === CALENDAR_ACTIVITY_MEETING_OUTCOMES.PENDING)
        .map((status) => status.id),
      status_ids: librariesStore.getCalendarActivityStatuses
        .filter((status) =>
          [CALENDAR_ACTIVITY_STATUSES.TO_DO, CALENDAR_ACTIVITY_STATUSES.OVERDUE].includes(status.code)
        )
        .map((status) => status.id),
    })
    overdueMeetingsCount.value = pagination.total
  } catch (error: any) {
    uiStore.showSnackBanner(error.message)
  }
}

const input = async () => {
  if (step.value === 1) {
    await markAsDone()
    if (overdueMeetingsCount.value) {
      step.value = 2
      return
    }
  }
  uiStore.showSnackBanner(
    $t('activities_updated_successfully', { num: selectedTasks.value.length }, selectedTasks.value.length)
  )
  emits('input')
  modelValue.value = false
}

const navigateToOtherOutcomes = () => {
  navigateTo('/activities?meeting_outcome_status_ids=1', { open: { target: '_blank' } })
}

const toggleSelectedTask = (task: Activity) => {
  if (selectedTasks.value.includes(task)) {
    selectedTasks.value = selectedTasks.value.filter((t) => t !== task)
    return
  }
  selectedTasks.value = [...selectedTasks.value, task]
}

const markAllAsDone = () => {
  selectedTasks.value = props.tasks
}

const markAsDone = async () => {
  saving.value = true
  const status = librariesStore.getCalendarActivityStatuses.find((s) => s.code === CALENDAR_ACTIVITY_STATUSES.DONE)
  if (!status) return

  const { error } = await setBulkActivityStatus(
    selectedTasks.value.map((task) => task.id!),
    status.id
  )
  if (error.value) {
    uiStore.showSnackBanner(error.value.message)
    saving.value = false
    return
  }
  saving.value = false
  step.value = 2
}
</script>

<style scoped></style>
